import React, { useState, useContext } from "react";
import { GlobalDataContext } from "../../context/context";
/* import Form from "../Contact/Form"; */
import { BsPinMap, BsEnvelope, BsTelephone } from 'react-icons/bs'

const Modal = () => {
  const [showModal, setShowModal] = useState(true);
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
      {showModal ? (
        <>
          <div
            className="relative z-50"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
          >
            <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity"></div>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white pt-5">
                    <div className="sm:flex sm:items-start">
                      <button
                        className="absolute top-3 right-3 px-2 rounded-full text-[#000] hover:text-[#00B3B3]"
                        id="modal-title"
                        onClick={() => setShowModal(false)}
                      >
                        <i className="fa fa-times"></i>
                      </button>
                      <div className="w-full flex justify-center item-center flex-col ">
                        <div className="w-full flex justify-center ">
                          <img
                            src={rpdata?.dbPrincipal?.logo}
                            className="md:w-[50%] w-[70%]"
                            alt="Not Found"
                            loading="lazy"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                    <h4 className="text-center">Request An Estimate Now</h4>
                    <div >
                    <ul className="w-full">
                                    {
                                        rpdata?.dbPrincipal?.phones?.map((phone, index) => {
                                            return (
                                                <li className="nd3 text-center text- mx-[20px] my-[20px]">
                                                  <button className="m-[auto] h-[100px] text-center text-[22px] font-bold ">
                                                    <a
                                                        href={`tel:+1${phone.phone}`}
                                                        className='flex items-center gap-3 pb-5 ease-in-out duration-500 linksColorFtHover '
                                                    >
                                                        <BsTelephone />
                                                        <span>{phone.phone}</span>
                                                    </a>
                                                    </button>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                    </div>
                   {/*  <Form /> */}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default Modal;
